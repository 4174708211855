<template>
  <VueFinalModal
    class="overflow-auto"
    content-class="modal-dialog modal-dialog-centered modal-lg"
    display-directive="show"
  >
    <div class="modal-content rounded-20">
      <div class="modal-header">
        <p class="heading-header-2 fw-normal modal-title fs-5">
          Приглашение
        </p>
        <button
          class="btn-close"
          type="button"
          aria-label="Закрыть"
          title="Закрыть"
          @click="confirm"
        />
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center mb-25">
          <div class="text-md-center custom-width">
            <p>Вакансия:</p>
            <SelectLayout
              v-model="$v.formVacancy.$model"
              class="form-control"
              target="professions"
              :class="$v.formVacancy.$error ? 'is-invalid' : ''"
              :options="vacancies"
              :reduce="(val) => val.id"
            />
          </div>
        </div>
        <p class="text-md-center">
          Пригласить на:
        </p>
        <div class="d-flex flex-md-row flex-column justify-content-center gap-md-25 gap-15">
          <AppButton
            class="fw-bold"
            :variant="BtnVariant.Tertiary"
            :active="formInviteTo === InviteTo.Interview"
            @click="formInviteTo = InviteTo.Interview"
          >
            Дополнительные вопросы
          </AppButton>
          <AppButton
            class="fw-bold"
            :variant="BtnVariant.Tertiary"
            :active="formInviteTo === InviteTo.Colloquy"
            @click="formInviteTo = InviteTo.Colloquy"
          >
            На собеседование
          </AppButton>
          <AppButton
            class="fw-bold"
            :variant="BtnVariant.Tertiary"
            :active="formInviteTo === InviteTo.Job"
            @click="formInviteTo = InviteTo.Job"
          >
            На работу
          </AppButton>
        </div>
        <hr v-show="formInviteTo !== InviteTo.Interview">
        <form
          v-show="formInviteTo === InviteTo.Colloquy"
          @submit.prevent="invite"
        >
          <div class="d-flex justify-content-center gap-25 mb-25">
            <AppButton
              type="button"
              class="fw-bold"
              :variant="BtnVariant.Tertiary"
              :active="formPlatform === Platform.Online"
              @click="changePlatform(Platform.Online)"
            >
              Онлайн
            </AppButton>
            <AppButton
              type="button"
              class="fw-bold"
              :variant="BtnVariant.Tertiary"
              :active="formPlatform === Platform.Offline"
              @click="changePlatform(Platform.Offline)"
            >
              В офис
            </AppButton>
          </div>
          <div class="d-flex flex-md-row flex-column mb-25 gap-md-0 gap-15">
            <label class="col-md-6 col-12 pe-md-5 pe-0">
              Дата и время:
              <DatetimePicker
                v-model="$v.formStartTime.$model"
                :class="$v.formStartTime.error ? 'is-invalid' : null"
              />
            </label>
            <label class="col-md-6 col-12 ps-md-5 ps-0">
              {{ formPlatform === Platform.Offline ? 'Адрес' : 'Площадка' }}:
              <input
                v-show="formPlatform === Platform.Offline"
                v-model.trim="formLocation"
                type="text"
                class="form-control"
                :class="$v.formStartTime.error ? 'is-invalid' : null"
              >
              <SelectLayout
                v-show="formPlatform === Platform.Online"
                v-model="location"
                class="form-control"
                :options="platformList"
                :clearable="false"
              />
            </label>
          </div>
          <label class="w-100">
            Детали:
            <input
              v-model.trim="formDescription"
              type="text"
              class="form-control"
            >
          </label>
          <div
            v-show="isForceTime"
            class="alert alert-accent mt-15 mb-0"
          >
            В указанное время(± 10мин.) уже есть собеседования, если вы все же хотите назначить собеседование на это время, нажмите кнопку "Пригласить" еще раз
          </div>
        </form>
        <form
          v-show="formInviteTo === InviteTo.Job"
          @submit.prevent="invite"
        >
          <AppTextArea
            v-model="$v.formInviteDescription.$model"
            class="mb-10 w-100"
            label="Комментарий для соискателя"
            :note="String(formInviteDescription?.length??0) + '/200'"
            error-message="Текст не может содержать более 200 символов"
            :is-invalid="$v.formInviteDescription.$error"
          />
        </form>
      </div>
      <div class="modal-footer">
        <AppButton
          class="rounded-10"
          variant
          @click="confirm"
        >
          Отмена
        </AppButton>
        <AppButton
          class="rounded-10"
          :disabled="$v.$invalid || isLoading"
          @click="invite"
        >
          Пригласить
        </AppButton>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';
import SelectLayout from "~/components/inputs/selectLayout.vue";
import { useApiResponses } from "~/composables/api/responses";
import { useApiVacancies } from "~/composables/api/vacancies";
import {BtnVariant} from "~/composables/enums/BtnVariant";
import AppTextArea from "~/components/inputs/AppTextArea.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, or, maxLength } from "@vuelidate/validators";
import DatetimePicker from "~/components/inputs/datetimePicker.vue";
import { useClient } from "~/stores/client";
import { onlineLocationList } from "~/composables/OnlineLocationList";

const { $toast } = useNuxtApp();
const apiVacancies = useApiVacancies();
const apiResponses = useApiResponses();
const clientStorage = useClient();

enum InviteTo {
  Interview = 4,
  Colloquy = 5,
  Job = 7
}
enum Platform {
  Offline,
  Online
}

const props = defineProps({
  client: { type: String, default: undefined }
});
const emits = defineEmits(['confirm', 'getVacancy']);

//-----VARIABLES-----\\
const platformList = onlineLocationList;
let defaultDate = new Date();
defaultDate.setDate(defaultDate.getDate() + 1);
defaultDate.setMinutes(0);

//-----STATE-----\\
const vacancies = ref<Object[]|null>(null);
const formVacancy = ref<string|null>(null);
const formInviteTo = ref<InviteTo|null>(InviteTo.Interview);
const formStartTime = ref<Date>(defaultDate);
const formPlatform = ref<Platform>(Platform.Online);
const formLocation = ref<string>(platformList[0]);
const formDescription = ref<string>('');
const formInviteDescription = ref<string>('');
const isLoading = ref<boolean>(false);
const isForceTime = ref<boolean>(false);

//-----COMPUTED-----\\
const $v = useVuelidate({
  formVacancy: { required },
  formInviteTo: { required },
  formPlatform: {
    inviteTo: or(
      required,
      () => formInviteTo.value !== InviteTo.Colloquy
    ),
  },
  formStartTime: {
    inviteTo: or(
      required,
      () => formInviteTo.value !== InviteTo.Colloquy
    ),
  },
  formInviteDescription: {
    inviteTo: or(
      maxLength(200),
      () => formInviteTo.value !== InviteTo.Job
    ),
  },
}, { formVacancy, formInviteTo, formPlatform, formStartTime, formInviteDescription });

//-----ASYNC-----\\
await apiVacancies.get(clientStorage.getActiveCompany?.id!, 'company').then(response => {
  response = response.data.value??response.error.value?.data;
  if (response.error.code !== 200) {
    //TODO: toast
    return;
  }

  vacancies.value = response.vacancies.items.map(item => ({
    id: item.id,
    title: item.city.title + ', ' + item.profession.title
  }));

  if (vacancies.value.length === 1) {
    formVacancy.value = vacancies.value[0].id;
  }
});

//-----METHODS-----\\
async function invite() {
  if ($v.value.$invalid || isLoading.value) {
    return;
  }

  isLoading.value = true;

  await apiResponses.invite({
    client: props.client,
    vacancy: formVacancy.value!,
    startTime: Math.floor(formStartTime.value.getTime() / 1000),
    platform: formPlatform.value!,
    location: formLocation.value,
    description: formDescription.value,
    message: formInviteDescription.value,
    type: formInviteTo.value!
  }, isForceTime.value).then(response => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code === 200) {
      confirm();
      $toast.success('Успешно');
    } else {
      $toast.error(response.error.clientMessage === null ? 'Ошибка' : response.error.clientMessage);
      isForceTime.value = response.error.code === 409 && response.isForceTime === true;
    }
  });

  isLoading.value = false;
}
function confirm() {
  isForceTime.value = false;
  emits('confirm');
}
function changePlatform(newPlatform: Platform) {
  if (formPlatform.value === newPlatform) {
    return;
  }

  formPlatform.value = newPlatform;
  if (newPlatform === 1) {
    formLocation.value = platformList[0];
  } else {
    formLocation.value = '';
  }
}
</script>

<style lang="scss">
//@import 'vue-toastification/src/scss/index';
.custom-width {
  @media (min-width: 768px) {
    min-width: 450px
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}
</style>
